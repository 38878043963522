import React from "react";

export const LandingPage = () => {
  return (
    <main className="mx-auto py-10 px-2 md:px-10 " style={{ maxWidth: 1600 }}>
      <section className="aspect-w-16 aspect-h-9">
        <iframe
          // className="mx-auto hidden lg:block"
          // width="1120"
          // height="630"
          src="https://www.youtube.com/embed/vs5biJo3xr0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </section>
      <section>
        <div className="py-24 md:py-48 flex justify-center">
          <a
            className=" mt-10 text-xl md:text-4xl font-bold text-center mx-auto underline"
            href="mailto:investors@stembionix.com"
          >
            investors@stembionix.com
          </a>
        </div>

        <img className="mx-auto h-48 object-cover" src="/plasmid.png" alt="" />

        <img
          className="mt-10 mx-auto w-full  object-cover"
          src="/Stembionix.png"
          alt=""
        />
        <h1 className="mt-10 text-center text-4xl text-gray-500">
          Advanced Longevity Systems
        </h1>
      </section>
    </main>
  );
};
