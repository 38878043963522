import React, { useEffect, useState } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";

import { Router } from "./router/Router";

function App() {
  return (
    <div className="">
      <BrowserRouter>
        <div className="flex-grow min-h-screen mt-0">
          <Router />
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
