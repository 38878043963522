import { Component, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { LandingPage } from "../pages/LandingPage";
import { ErrorBoundary } from "./ErrorBoundary";

export class Router extends Component {
  state = {
    hasError: false,
  };

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true });
    if (process.env.NODE_ENV !== "production") {
      console.group("ROUTER ERROR: COMPONENT DID CATCH");
      console.error(error, info);
      console.groupEnd();
    }
  }

  render() {
    if (process.env.NODE_ENV !== "production") {
      // console.group('ROUTER');
      // console.log(this.props);
      // console.groupEnd();
    }

    return (
      <ErrorBoundary>
        <Suspense fallback={<div>fallback</div>}>
          <Switch>
            <Route exact path="/" component={LandingPage} />

            <Route path="/:slug">
              <Redirect to="/" />
            </Route>
          </Switch>
        </Suspense>
      </ErrorBoundary>
    );
  }
}
